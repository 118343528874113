<template>
    <section>
        <div class="content-wrapper">
            <div class="page-header">
                <h3 class="page-title">
                    Mes cartes cadeaux offertes
                </h3>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                    <li class="breadcrumb-item">Mes cadeaux</li>
                    <li class="breadcrumb-item active" aria-current="page">Nom du destinataire</li>
                    </ol>
                </nav>
            </div>
            <div class="row">
                
                <!-- Titre et info -->
                <div class="container-fluid ">
                    <div class="col-lg-3 pl-0" v-if="voucher.receiver">
                        <p class="mb-0 mt-5">Pour :</p>
                        <h3> {{voucher.receiver.firstname}} {{voucher.receiver.lastname}}</h3>
                        <p >Email : </p>
                        <h3>{{voucher.receiver.email}}</h3>
                        <p >Téléphone : </p>
                        <h3>{{voucher.receiver.tel}}</h3>
                    </div>
                </div>
                
                <!-- Aperçu de la carte imprimée -->
                <h3 class="text-right w-100">Aperçu de la carte</h3>
                <div class="position-relative" v-if="voucher.sender">
                    <img src="@/assets/images/import/voucher_page_1.png" style="width:100%" alt="">
                        <!-- contenu dynamique -->
                        <div class="content">
                        <div class="price">{{voucher.price}}€</div>
                        <img src="@/assets/images/import/voucher_page_2.png" style="width:100%" alt="">
                        <p class="from">{{ voucher.sender.firstname }} {{ voucher.sender.lastname }}</p>
                        <p class="date">{{formatDate2(voucher.datepublished)}}</p>
                        <p class="message">{{ voucher.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import { dateMixin } from './mixins/date'
export default {
    name:"printedVoucher",
    mixins:[dateMixin],
    data(){
        return{
            ulid:'',
            voucher:''
        }
    },
    methods:{
        getVoucher(){
            axios.get(`vouchers/${this.ulid}`)
            .then(resGetVoucher => {
                console.log({resGetVoucher});
                this.voucher = resGetVoucher.data
            })
            .catch(errGetVoucher =>{
                console.log({errGetVoucher}); errGetVoucher
            })
        }
    },
    mounted(){
        this.ulid = this.$route.params.ulid
        this.getVoucher()
    },
    beforeCreate() {
        // mettre X-Auth-token sur toutes les requêtes de la page
        axios.defaults.headers.common['X-AUTH-TOKEN'] = localStorage.getItem('token');
    }
}
</script>
<style lang="scss">
    page {
    background: white;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
    }
    .content{
        position:relative;
    }
  
  @media screen and (min-width:1200px){
    .price{
    position: absolute;
    font-size: 50px;
    top: 9rem;
    left: 15rem;
    color: white;
    }
    .from{
        position: absolute;
        top: 29rem;
        left: 24rem;
    }
    .date{
        position: absolute;
        top: 33.5rem;
        left: 24rem;
    }
    .message{
        position: absolute;
        max-width: 28rem;
        top: 37rem;
        left: 23rem;
    }
  }
  @media screen and (min-width: 992px) and (max-width:1199px){
    .price{
        position: absolute;
        font-size: 50px;
        top: 10rem;
        left: 13rem;
        color: white;
    }
    .from{
        position: absolute;
        top: 24rem;
        left: 22rem;
    }
    .date{
        position: absolute;
        top: 28rem;
        left: 22rem;
    }
    .message{
        position: absolute;
        max-width: 28rem;
        top: 31rem;
        left: 20rem;
    }
}
@media screen and (max-width: 992px) and (min-width: 766px) {
    .price{
        position: absolute;
        font-size: 50px;
        top: 6rem;
        left: 9rem;
        color: white;
    }
    .from{
        position: absolute;
        top: 18rem;
        left: 16rem;
    }
    .date{
        position: absolute;
        top: 21rem;
        left: 16rem;
    }
    .message{
        position: absolute;
        max-width: 23rem;
        top: 24rem;
        left: 15rem
    }
}
@media screen and (max-width: 765px) {
    .price{
        position: absolute;
        font-size: 50px;
        top: 4rem;
        left: 6rem;
        color: white;
    }
    .from{
        position: absolute;
        top: 13.5rem;
        left: 11rem;
        font-size: 13px
    }
    .date{
        position: absolute;
        top: 15.5rem;
        left: 11rem;
        font-size: 13px
    }
    .message{
        position: absolute;
        max-width: 23rem;
        top: 18rem;
        left: 11rem;
        font-size: 11px
    }
}
</style>